"use strict";
import { ReactComponent as WinterUni } from "assets/svg/winter-uni.svg";
import { useTranslation } from "react-i18next";
export default function HolidayUniIcon(props) {
  const { t } = useTranslation();
  const currentMonth = `${(/* @__PURE__ */ new Date()).getMonth() + 1}`;
  const MONTH_TO_HOLIDAY_UNI = {
    "12": (props2) => <WinterUni title={t("common.happyHolidays")} {...props2} />,
    "1": (props2) => <WinterUni {...props2} />
  };
  const HolidayUni = MONTH_TO_HOLIDAY_UNI[currentMonth];
  return HolidayUni ? <HolidayUni {...props} /> : null;
}
